.kitchen {
    height: 100%;
    grid-area: body;
}

.bgimg {
    background-image: url(../../img/kitchen2.webp);
    background-size: cover;
    height: 1200px;
}

.content {
    padding: 50px;
}

.title {
    background: rgba(40, 40, 40, 0.9);
    color: white;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
}

.descr {
    max-width: 700px;
    padding: 20px;
    background: rgba(40, 40, 40, 0.9);
    color: white;
    border-radius: 10px;
}

@media(max-width: 800px) {
    .title {
        font-size: 20px;
    }

    .descr {
        font-size: 14px;
        max-width: 600px;
    }
}