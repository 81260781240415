.home {
    position: relative;
    grid-area: body;
    height: 60em;
}

.bgimg {
    background-image: url(./Budynek.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 70em;
    position: absolute;
    top: -10em;
    z-index: -1;
}

.content {
    margin-top: 170px;
    background: rgba(40, 40, 40, 0.9);
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 70px;
    color: azure;
    padding: 30px 100px 10px 200px;
    width: 920px;
}

.descr {
    color: white;
    font-size: 28px;
    padding: 0 100px 30px 200px;
}

.btn {
    font-size: 20px;
    padding: 30px 80px;
    background: #9a7ee6;
    color: white;
    border: none;
    border-radius: 20px;
    text-decoration: none;
    cursor: pointer;
}

@media(max-width: 1000px) {
    .title {
        font-size: 40px;
        padding: 30px 50px 10px 50px;
        width: 920px;
    }
    
    .descr {
        font-size: 18px;
        padding: 0 100px 30px 200px;
    }
}

@media(max-width: 600px) {
    .title {
        font-size: 30px;
        padding: 30px 30px 30px 30px;
        width: 520px;
    }
    
    .descr {
        font-size: 14px;
        padding: 0 100px 30px 150px;
    }
}

@media(max-width: 400px) {
    .title {
        font-size: 24px;
        padding: 30px 30px 30px 30px;
        width: 300px;
    }
}