.contacts {
    height: 100%;
    grid-area: body;
}

.bgimg {
    background-image: url(../../img/slide5.webp);
    background-position: center;
    background-size: cover;
    height: 1000px;
}

.content {
    padding: 50px;
    
}

.title {
    color: white;
    background: rgba(40, 40, 40, 0.9);
    display: inline-block;
    padding: 5px;
    border-radius: 10px;
}

.descr {
    max-width: 1200px;
    padding: 20px;
    background: rgba(40, 40, 40, 0.9);
    color: white;
    border-radius: 10px;
}

.info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.data {
    background: rgba(40, 40, 40, 0.8);
    border-radius: 20px;
    padding: 20px 20px;
    color: white;
    display: flex;
    flex-direction: column;
}

.data p {
    margin-bottom: 20px;
}

.data a {
    color: white;
    text-decoration: none;
    margin-left: 10px;
    margin-bottom: 20px;
    font-size: large;
}

.address p {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}


@media(max-width: 800px) {
    .title {
        font-size: 20px;
    }

    .descr {
        font-size: 14px;
        max-width: 600px;
    }
}