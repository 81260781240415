.swiper {
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.image {
  /* width: 1200px; */
  height: 900px;
}

/* .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 70px;
} */



@media(max-width: 600px) {
  .image {
    /* width: 700px; */
    height: 400px;
  }
}


@media(max-width: 400px) {
  .image {
    /* width: 700px; */
    height: 300px;
  }
}