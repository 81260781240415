.halls {
    height: 1000px;
    grid-area: body;
}

.bgimg {
    background-image: url(../../img/hall.webp);
    background-size: cover;
    height: 100%;
}

.content {
        padding: 50px;
}

.title {
    background: rgba(40, 40, 40, 0.9);
    color: white;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
}

.descr {
    font-size: 16px;
    max-width: 800px;
    padding: 20px;
    background: rgba(40, 40, 40, 0.9);
    color: white;
    border-radius: 10px;
}

@media(max-width: 800px) {
    .title {
        font-size: 20px;
    }

    .descr {
        font-size: 14px;
        max-width: 600px;
    }
}