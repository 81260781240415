.galeria {
    height: 100%;
    grid-area: body;
}

.bgimg {
    /* background-image: url(../../img/hall15.jpeg); */
    background: rgba(40, 40, 40, 0.4);
    background-size: cover;
    height: 1500px;
    padding: 10px 10px 30px 10px;
}

.content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    margin-bottom: 50px;
    color: white;
    background: rgba(40, 40, 40, 0.9);
    display: inline-block;
    padding: 5px;
    border-radius: 10px;
}

.descr {
    color: white;
}

.image {
    width: 1000px;
    height: 700px;
}


@media(max-width: 800px) {

    .content {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .title {
        font-size: 20px;
    }

    .descr {
        font-size: 14px;
    }
}

@media(max-width: 600px) {
    .bgimg {
        height: 900px;
    }
}


@media(max-width: 400px) {
    .bgimg {
        height: 850px;
    }
}