.container {
    background: rgba(40, 40, 40, 0.7);
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
    grid-area: header;
    position: relative;
}

.openMenu {
    display: none;
}

.burgerMenuContainer {
    display: none;
    position: absolute;
}

.logo {
    width: 50px;
    height: 50px;
}

.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar a {
    text-decoration: none;
    color: white;
    font-size: 16px;
}

.navbar a:not(:last-child) {
    margin-right: 50px;
}

.btnNav {
    display: none;
}

.btnContainer {
    display: flex;
    align-items: center;
}

.btn {
    font-size: 14px;
    padding: 10px 20px;
    background: #9a7ee6;
    color: white;
    border: none;
    border-radius: 10px;
    text-decoration: none;
}

.btn:hover {
    background-color: rgb(78, 78, 225);
    color: white;
}
.btn:focus {
    background-color: rgb(69, 69, 75);
    color: white;
}
.btn:active {
    background-color: rgb(43, 43, 197);
    color: white;
}



@media(max-width: 1200px) {
    .navbar {
        display: none;
    }
    
    .openMenu {
        display: block;
        border: none;
        background: transparent; 
    }
    
    .iconMenu {
        color: white;
        font-size: 30px;
    }
    
    .iconMenu:hover {
        color: rgb(133, 108, 221);
    }
}

@media(max-width: 600px) {
    .container {
        padding: 10px 30px;

    }

    .logo {
        display: none;
    }
    
    .openMenu {
        display: block;
    }
}