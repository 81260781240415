.plan {
    height: 100%;
    grid-area: body;
}

.bgimg {
    background-image: url(../../img/plan.webp);
    background-size: cover;
    height: 1200px;
}

.content {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    background: rgba(40, 40, 40, 0.9);
    color: white;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
}

.descr {
    max-width: 600px;
    padding: 50px;
    background: rgba(40, 40, 40, 0.9);
    color: white;
    border-radius: 10px;
    font-size: 20px;
}

.descr li {
    padding: 20px;
}

@media(max-width: 800px) {
    .bgimg {
        background-position: center;
        height: 1600px;
    }

    .title {
        font-size: 20px;
    }

    .descr {
        font-size: 14px;
        max-width: 600px;
    }
}